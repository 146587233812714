import React from 'react'
import PropTypes from 'prop-types'

function SecondaryButton({ text, onClick, type }) {
  return (
    <button
      onClick={onClick}
      type={type ? type : 'button'}
      className="bg-gray-400 text-gray-900 w-max px-12 py-2 rounded-xl"
    >
      {text}
    </button>
  )
}

SecondaryButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default SecondaryButton
