'use client'
import React, { useState, useEffect } from 'react'
import { Table } from 'flowbite-react'
import Pagination from '../../../components/pagination/Pagination'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import SearchBox from '../../../components/searchbox/SearchBox'

function MonitoringTable({ tableData, uniqueKeysArray }) {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsOnCurrentPage, setItemsOnCurrentPage] = useState([])
  const [tableDataToShow, setTableDataToShow] = useState([])

  const itemsPerPage = 10

  const totalItems = tableData.length
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  // Function to sort data by Timestamp
  const sortByTimestamp = (data) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.Timestamp)
      const dateB = new Date(b.Timestamp)
      return dateB - dateA // Sort in descending order
    })
  }

  useEffect(() => {
    const sortedData = sortByTimestamp([...tableData])
    setTableDataToShow(sortedData)
  }, [tableData])

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    setItemsOnCurrentPage(tableDataToShow.slice(startIndex, endIndex))
  }, [currentPage, tableDataToShow])

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase()

    if (searchValue === '') {
      setTableDataToShow(sortByTimestamp([...tableData])) // Reset to sorted data
    } else {
      const filteredData = tableData.filter((item) => {
        for (let key of uniqueKeysArray) {
          if (item[key] && item[key].toString().toLowerCase().includes(searchValue)) {
            return true
          }
        }
        return false
      })
      setTableDataToShow(filteredData)
    }
  }

  return (
    <div className="container w-full p-4 bg-white rounded-lg shadow-sm mt-20">
      {tableData.length > 0 ? (
        <>
          <SearchBox placeholder={t('monitoring.quick-search')} onSearch={(e) => handleSearch(e)} />
          <Table hoverable>
            <Table.Head className="bg-white border-b-4 text-[#71717A]">
              {uniqueKeysArray.map((key, index) => (
                <Table.HeadCell key={index} className="bg-white capitalize font-medium text-[16px] text-center">
                  {key}
                </Table.HeadCell>
              ))}
            </Table.Head>
            <Table.Body className="divide-y text-black">
              {itemsOnCurrentPage.map((item, rowIndex) => (
                <Table.Row key={rowIndex}>
                  {uniqueKeysArray.map((key, cellIndex) => (
                    <Table.Cell key={cellIndex} className="text-center">
                      {item[key]}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <div className="border-t container w-full pt-4">
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
          </div>
        </>
      ) : (
        <span className="m-8">{t('monitoring.no-requests')}</span>
      )}
    </div>
  )
}

MonitoringTable.propTypes = {
  t: PropTypes.func,
  tableData: PropTypes.array,
  uniqueKeysArray: PropTypes.array,
}

export default MonitoringTable
