import { Toast } from 'flowbite-react'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { IoClose } from 'react-icons/io5'
import { FaCircleExclamation } from 'react-icons/fa6'
import { MdOutlineDoneOutline } from 'react-icons/md'
import { useErrorContext } from '../../context/ErrorContext'
import { useSuccessContext } from '../../context/SuccessContext'

function ToastMessage({ type }) {
  const { errorMsg, setErrorMsg } = useErrorContext()
  const { successMsg, setSuccessMsg } = useSuccessContext()

  const message = type === 'success' ? successMsg : errorMsg
  const setMessage = type === 'success' ? setSuccessMsg : setErrorMsg

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [message, setMessage])

  const icon =
    type === 'success' ? <MdOutlineDoneOutline className="h-5 w-5" /> : <FaCircleExclamation className="h-5 w-5" />
  const backgroundColor = type === 'success' ? 'bg-green-100' : 'bg-red-100'
  const textColor = type === 'success' ? 'text-green-600' : 'text-red-600'

  return (
    message && (
      <Toast className="fixed right-8 top-16 z-[500] overflow-hidden">
        <div
          className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${backgroundColor} ${textColor}`}
        >
          {icon}
        </div>
        <p
          className="ml-3 text-sm font-normal text-wrap w-full overflow-hidden"
          style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        >
          {message}
        </p>
        <button className="right-2 top-2 absolute" onClick={() => setMessage(null)}>
          <IoClose />
        </button>
      </Toast>
    )
  )
}

ToastMessage.propTypes = {
  type: PropTypes.oneOf(['error', 'success']).isRequired,
}

export default ToastMessage
