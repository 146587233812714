'use client'
import React from 'react'
import { Pagination } from 'flowbite-react'
import PropTypes from 'prop-types'

function Component({ currentPage, setCurrentPage, totalPages }) {
  const onPageChange = (page) => setCurrentPage(page)

  return (
    <div className="flex overflow-x-auto sm:justify-center">
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
    </div>
  )
}

Component.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalPages: PropTypes.number,
}

export default Component
