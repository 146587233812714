import React from 'react'
import './sidebar.css'
import { Sidebar } from 'flowbite-react'
import { useLocation } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../language-switcher/LanguageSwitcher'
import { useUserContext } from '../../context/UserContext'
import SidebarLink from './SidebarLink'
import SidebarCollapse from './SidebarCollapse'

function Component() {
  const { t } = useTranslation()
  const { isDeployed } = useUserContext()
  const location = useLocation()
  const { pathname } = location

  return (
    <Sidebar className="h-screen relative border-r-2">
      <Sidebar.Logo href="https://fill-easy.com" img="/icons/favicon.svg">
        Portal
      </Sidebar.Logo>
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          <SidebarCollapse label={t('sidebar.onboarding')}>
            <SidebarLink
              to="/deployment"
              label={t('sidebar.deployment')}
              isActive={pathname === '/deployment' || pathname === '/'}
              isAccessible={true}
            />
            <SidebarLink
              to="/secrets"
              label={t('sidebar.secrets')}
              isActive={pathname === '/secrets'}
              isAccessible={isDeployed}
            />
            <SidebarLink
              to="/access-keys"
              label={t('sidebar.access-keys')}
              isActive={pathname === '/access-keys'}
              isAccessible={isDeployed}
            />
            <SidebarLink
              to="/ip-whitelisting"
              label={t('sidebar.ip-whitelisting')}
              isActive={pathname === '/ip-whitelisting'}
              isAccessible={isDeployed}
            />
          </SidebarCollapse>
          <SidebarCollapse label={t('sidebar.insights')}>
            <SidebarLink
              to="/monitoring"
              label={t('sidebar.monitoring')}
              isActive={pathname === '/monitoring'}
              isAccessible={isDeployed}
            />
            <SidebarLink
              to="/billing"
              label={t('sidebar.billing')}
              isActive={pathname === '/billing'}
              isAccessible={isDeployed}
            />
          </SidebarCollapse>
          <SidebarCollapse label={t('sidebar.resources')}>
            <SidebarLink to="/faqs" label={t('sidebar.faqs')} isActive={pathname === '/faqs'} isAccessible={true} />
            <Sidebar.Item
              className="text-[#475467] underline text-sm"
              href="https://documenter.getpostman.com/view/22570316/2s9Y5Zugnh"
              target="_blank"
            >
              <div className="flex items-center gap-1">
                <span>{t('sidebar.documentation')}</span>
                <MdArrowOutward className="w-max" size={22} />
              </div>
            </Sidebar.Item>
          </SidebarCollapse>
          <SidebarCollapse label={t('sidebar.contact')}>
            <SidebarLink
              to="/request-for-data-access"
              label={t('sidebar.request-for-data-access')}
              isActive={pathname === '/request-for-data-access'}
              isAccessible={true}
            />
            <SidebarLink
              to="/general-enquiries"
              label={t('sidebar.general-enquiries')}
              isActive={pathname === '/general-enquiries'}
              isAccessible={true}
            />
          </SidebarCollapse>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
      <div className="absolute bottom-6 ml-3">
        <LanguageSwitcher />
      </div>
    </Sidebar>
  )
}

export default Component
