import React from 'react'
import Sidebar from './components/sidebar/Sidebar'
import Topbar from './components/topbar/Topbar'
import Footer from './components/footer/Footer'
import ToastMessage from './components/toast/ToastMessage'
import './layout.css'
import PropTypes from 'prop-types'
import DeployCard from './components/deployCard/deployCard'

function Layout({ children }) {
  return (
    <div className="dashboard-container w-screen overflow-hidden">
      <ToastMessage type="error" />
      <ToastMessage type="success" />
      <Sidebar />
      <main className="main-content relative h-full">
        <div className="content">
          <Topbar />
          <DeployCard />
          <div className="mx-20 my-6">{children}</div>
          <Footer />
        </div>
      </main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
}

export default Layout
