import React from 'react'
import { Tooltip } from 'flowbite-react'
import { FiInfo } from 'react-icons/fi'
import PropTypes from 'prop-types'

export function TooltipWithIcon({ text }) {
  return (
    <Tooltip content={text} style="light" className="text-[#475467] text-sm">
      <FiInfo size={18} />
    </Tooltip>
  )
}

TooltipWithIcon.propTypes = {
  text: PropTypes.string.isRequired,
}

export function DefaultTooltip({ text, children }) {
  return (
    <Tooltip content={text} style="light" className="text-[#475467] text-sm">
      {children}
    </Tooltip>
  )
}

DefaultTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}
