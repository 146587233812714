import React from 'react'
import { Modal } from 'flowbite-react'
import PropTypes from 'prop-types'

function ModalContainer({ openModal, setOpenModal, modalHeader, modalBody }) {
  return (
    <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
      <Modal.Header className="text-gray-900">{modalHeader}</Modal.Header>
      <Modal.Body className="text-gray-800 font-normal">{modalBody}</Modal.Body>
    </Modal>
  )
}

ModalContainer.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  modalHeader: PropTypes.string.isRequired,
  modalBody: PropTypes.any.isRequired,
}

export default ModalContainer
