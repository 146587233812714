import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'

const LoaderContext = createContext()

const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  const value = {
    isLoading,
    setIsLoading,
  }

  return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
}

export const useLoaderContext = () => useContext(LoaderContext)

LoaderProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default LoaderProvider
