import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'

const SuccessContext = createContext()

const SuccessProvider = ({ children }) => {
  const [successMsg, setSuccessMsg] = useState(undefined)

  const value = {
    successMsg,
    setSuccessMsg,
  }
  return <SuccessContext.Provider value={value}>{children}</SuccessContext.Provider>
}

export const useSuccessContext = () => useContext(SuccessContext)

SuccessProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default SuccessProvider
