import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BiShow, BiHide } from 'react-icons/bi'

function AccessKeyTextArea({ value }) {
  const [visible, setVisible] = useState(false)

  return (
    <div className="w-full relative">
      <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 shadow-sm h-max min-h-20 pr-10">
        {visible ? (
          <span className="max-w-full text-wrap" style={{ wordWrap: 'break-word' }}>
            {value}
          </span>
        ) : (
          <span
            className="max-w-full text-wrap text-xs leading-[0px] tracking-[0px]"
            style={{ wordWrap: 'break-word' }}
          >
            {value && value.replace(/./g, '*')}
          </span>
        )}
      </div>
      <div className="absolute right-3 top-3 flex items-center ps-3.5">
        {visible ? (
          <button onClick={() => setVisible(false)}>
            <BiHide size={18} />
          </button>
        ) : (
          <button onClick={() => setVisible(true)}>
            <BiShow size={18} />
          </button>
        )}
      </div>
    </div>
  )
}

AccessKeyTextArea.propTypes = {
  value: PropTypes.string.isRequired,
}

export default AccessKeyTextArea
