import React from 'react'
import { Spinner } from 'flowbite-react'

const LoadSpinner = () => {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <Spinner className="text-gray-200 h-10 w-10 fill-[#FED201]" />
    </div>
  )
}

export default LoadSpinner
