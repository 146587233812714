import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextWithModal from '../input/text/text-with-modal/TextWithModal'
import TextInput from '../input/text/text-input/TextInput'
import FileUpload from '../input/file-upload/file-upload/FileUpload'
import FileUploadWithModal from '../input/file-upload/file-upload-with-modal/FileUploadWithModal'
import PrimaryButton from '../buttons/PrimaryButton'
import useForm from './useForm'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '../../context/UserContext'

const Form = ({ initialValues, validationSchema, onSubmit, currentValues, fields, service }) => {
  const { t } = useTranslation()
  const { user } = useUserContext()

  const subscribed = user?.attributes[`custom:deploy-${service}`] === 'true'

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    checkIfValidationRulesPassed,
    checkIfValuesChanged,
  } = useForm(initialValues, validationSchema, onSubmit, currentValues)

  const [tooltipText, setTooltipText] = useState('')

  useEffect(() => {
    if (!subscribed) {
      setTooltipText(t('secrets.not-subscribed'))
    } else if (!checkIfValuesChanged({ values, currentValues })) {
      setTooltipText(t('secrets.no-change'))
    } else if (!checkIfValidationRulesPassed({ errors, values })) {
      setTooltipText(t('secrets.validation-error'))
    }
  }, [subscribed, errors, values, currentValues, checkIfValidationRulesPassed, checkIfValuesChanged, t])

  return (
    <form onSubmit={handleSubmit}>
      {fields.map(({ name, label, placeholder, modalBodyContent, type }) => {
        if (type === 'file') {
          return (
            <FileUploadWithModal
              key={name}
              label={label}
              name={name}
              modalHeaderContent={label}
              modalBodyContent={modalBodyContent}
            >
              <FileUpload onChange={(files) => setFieldValue(name, files[0])} error={errors[name]} />
            </FileUploadWithModal>
          )
        }

        return (
          <TextWithModal
            key={name}
            label={label}
            name={name}
            modalHeaderContent={label}
            modalBodyContent={modalBodyContent}
          >
            <TextInput
              name={name}
              placeholder={placeholder}
              defaultInputVisibility={false}
              value={values[name]}
              onChange={handleChange}
              error={errors[name]}
            />
          </TextWithModal>
        )
      })}
      <div className="w-full flex justify-center">
        <PrimaryButton
          text={t('save')}
          type="submit"
          isDisabled={
            !subscribed ||
            !checkIfValidationRulesPassed({ errors, values }) ||
            !checkIfValuesChanged({ values, currentValues })
          }
          tooltipText={tooltipText}
        />
      </div>
    </form>
  )
}

Form.propTypes = {
  service: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentValues: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      modalBodyContent: PropTypes.node.isRequired,
      type: PropTypes.oneOf(['text', 'file']).isRequired,
    }),
  ).isRequired,
}

export default Form
