import React from 'react'
import { Tabs } from 'flowbite-react'
import './style-one.css'
import SingpassTabs from './style-two/SingpassTabs'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import IAmSmartTab from './iamsmart/iAmSmartTab'

function SecretsTabs({ selectedTab, setSelectedTab }) {
  const { t } = useTranslation()

  return (
    <Tabs aria-label="Pills" style="pills" onActiveTabChange={(tab) => setSelectedTab(tab + 1)}>
      <Tabs.Item
        active={selectedTab === 1}
        title={
          <div className="flex items-center gap-3">
            <img src="/icons/iamsmart.svg" alt="" className="h-6 object-contain" />
            <span className="font-normal text-[16px]">{t('secrets.iamsmart.heading')}</span>
          </div>
        }
      >
        <IAmSmartTab />
      </Tabs.Item>
      <Tabs.Item
        active={selectedTab === 2}
        title={
          <div className="flex items-center gap-3">
            <img src="/icons/singpass.svg" alt="" className="h-6 object-contain" />
            <span className="font-normal text-[16px]">{t('secrets.singpass.heading')}</span>
          </div>
        }
      >
        <SingpassTabs />
      </Tabs.Item>
      <Tabs.Item
        active={selectedTab === 3}
        title={
          <div className="flex items-center gap-3">
            <img src="/icons/uaepass.svg" alt="" className="h-6 object-contain" />
            <span className="font-normal text-[16px]">{t('secrets.uaepass.heading')}</span>
          </div>
        }
      >
        <h1 className="font-semibold text-[30px]">{t('secrets.under-construction')}</h1>
      </Tabs.Item>
    </Tabs>
  )
}

SecretsTabs.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
}

export default SecretsTabs
