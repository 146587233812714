import React from 'react'
import RoutesContainer from './Routes'
import UserProvider from './context/UserContext'
import ErrorProvider from './context/ErrorContext'
import SuccessProvider from './context/SuccessContext'
import LoaderProvider from './context/LoaderContext'
import { RTLWrapper } from './components/rtlWrapper/RtlWrapper'

function App() {
  return (
    <LoaderProvider>
      <ErrorProvider>
        <SuccessProvider>
          <RTLWrapper>
            <UserProvider>
              <RoutesContainer />
            </UserProvider>
          </RTLWrapper>
        </SuccessProvider>
      </ErrorProvider>
    </LoaderProvider>
  )
}

export default App
