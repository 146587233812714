import React, { useEffect } from 'react'
import { Toast } from 'flowbite-react'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '../../context/UserContext'
import { getDeploymentStatus } from '../../api/deployment'
import { useErrorContext } from '../../context/ErrorContext'
import StatusIndicator from '../status/StatusIndicator'

function DeployCard() {
  const { t } = useTranslation()
  const { deploymentStatus, setDeploymentStatus } = useUserContext()
  const { setErrorMsg } = useErrorContext()

  useEffect(() => {
    if (deploymentStatus.status === 'deploying') {
      const interval = setInterval(() => {
        getDeploymentStatus(setErrorMsg).then((status) => {
          setDeploymentStatus(status)
        })
      }, 2000)
      return () => clearInterval(interval)
    }
  }, [deploymentStatus.status, setDeploymentStatus, setErrorMsg])

  if (!deploymentStatus.status || deploymentStatus.status !== 'deploying') {
    return null
  }

  return (
    <Toast className="fixed right-8 top-16 z-[500] p-4 bg-white shadow-lg rounded-lg">
      <div className="p-4">
        <h2 className="font-bold text-gray-900 mb-2">{t('deployment.status-popup.heading')}</h2>
        <StatusIndicator status={deploymentStatus.steps.createAccount} text={t('deployment.status-popup.step-1')} />
        <StatusIndicator status={deploymentStatus.steps.deployStack} text={t('deployment.status-popup.step-2')} />
        <StatusIndicator status={deploymentStatus.steps.updateDns} text={t('deployment.status-popup.step-3')} />
        <p className="text-sm text-gray-700 mt-4">{t('deployment.status-popup.description')}</p>
      </div>
    </Toast>
  )
}

export default DeployCard
