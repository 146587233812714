import React from 'react'
import IpTextInput from '../input/text/ip-text-input/IpTextInput'
import PropTypes from 'prop-types'

function Ips({ ips, setIps, t }) {
  return (
    <div>
      <div className="flex flex-col gap-3">
        {ips.map((ip, index) => (
          <IpTextInput
            placeholder={t('ipwhitelisting.enter-ip')}
            name="ip1"
            id="ip1"
            key={index}
            value={ip}
            onDelete={() => {
              setIps((prev) => prev.filter((_, i) => i !== index))
            }}
            onChange={(e) => {
              const { value } = e.target
              setIps((prev) => {
                const newIps = [...prev]
                newIps[index] = value
                return newIps
              })
            }}
            error={
              !/^([0-9]{1,3}\.){3}[0-9]{1,3}\/[0-9]{1,2}$/.test(ip)
                ? !/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(ip)
                  ? t('ipwhitelisting.invalid-ip')
                  : ''
                : ''
            }
          />
        ))}
      </div>
    </div>
  )
}

Ips.propTypes = {
  ips: PropTypes.array.isRequired,
  setIps: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default Ips
