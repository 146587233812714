import React from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { MdClear } from 'react-icons/md'
import PropTypes from 'prop-types'

function IpTextInput({ placeholder, error, name, value, onChange, onDelete }) {
  return (
    <div>
      <div className="relative">
        <div className="absolute right-3 inset-y-0 flex items-center ps-3.5">
          <button onClick={onDelete}>
            <MdClear size={18} color="red" />
          </button>
        </div>
        <input
          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-sm pr-10`}
          placeholder={placeholder}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
        />
      </div>
      {value && error && (
        <span className="font-light text-xs text-[#F05A5A] flex items-center gap-1 mt-1">
          <MdErrorOutline size={18} />
          {error}
        </span>
      )}
    </div>
  )
}

IpTextInput.propTypes = {
  placeholder: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
}

export default IpTextInput
