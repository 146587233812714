import React from 'react'
import PropTypes from 'prop-types'
import { DefaultTooltip } from '../tooltips/FETooltips'
import { FaLock } from 'react-icons/fa'

function PrimaryButton({ type, text, onClick, isDisabled, tooltipText }) {
  const button = (
    <button
      onClick={onClick}
      type={type ? type : 'button'}
      className={
        !isDisabled
          ? 'bg-[#FED201] w-max px-12 py-2 rounded-xl'
          : 'cursor-not-allowed bg-gray-200 text-gray-400 w-max px-12 py-2 rounded-xl flex items-center justify-center'
      }
      disabled={isDisabled}
    >
      {isDisabled && <FaLock className="mr-2" />}
      {text}
    </button>
  )

  return (
    <div className="relative group w-max">
      {tooltipText && isDisabled ? <DefaultTooltip text={tooltipText}>{button}</DefaultTooltip> : button}
    </div>
  )
}

PrimaryButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  tooltipText: PropTypes.string,
}

export default PrimaryButton
