import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'

const ErrorContext = createContext()

const ErrorProvider = ({ children }) => {
  const [errorMsg, setErrorMsg] = useState(undefined)

  const value = {
    errorMsg,
    setErrorMsg,
  }
  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
}

export const useErrorContext = () => useContext(ErrorContext)

ErrorProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ErrorProvider
