import React from 'react'
import { FiCopy } from 'react-icons/fi'
import { IoRefresh } from 'react-icons/io5'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PropTypes from 'prop-types'
import { TooltipWithIcon } from '../../tooltips/FETooltips'
import { DefaultTooltip } from '../../tooltips/FETooltips'
import { useTranslation } from 'react-i18next'

function AccessKeyWrapper({
  label,
  tooltipText,
  name,
  refreshFunc,
  hideRefreshButton,
  inputValue,
  isRegeneratingNewKey,
  regeneratingCredential,
  children,
}) {
  const { t } = useTranslation()

  return (
    <div className="mb-8">
      <div className="mb-2 flex justify-between">
        <div className="flex items-center gap-2 text-sm font-normal text-gray-900 dark:text-white">
          <label htmlFor={name}>
            <span>{label}</span>
            <br />
            {/* <span className="text-xs text-gray-600">{desc}</span> */}
          </label>
          <TooltipWithIcon text={tooltipText} />
        </div>
        <div className="flex items-center gap-2">
          {!hideRefreshButton && (
            <button
              onClick={refreshFunc}
              disabled={isRegeneratingNewKey}
              className={`${isRegeneratingNewKey ? 'cursor-not-allowed' : ''}`}
            >
              {isRegeneratingNewKey && regeneratingCredential === name ? (
                <IoRefresh className="animate-spin" size={18} />
              ) : (
                <DefaultTooltip text={t('accesskeys.rotate')}>
                  <IoRefresh size={18} />
                </DefaultTooltip>
              )}
            </button>
          )}
          <DefaultTooltip text={t('copy')}>
            <CopyToClipboard text={inputValue}>
              <FiCopy size={20} className="text-[#475467] cursor-pointer" />
            </CopyToClipboard>
          </DefaultTooltip>
        </div>
      </div>

      {children}
    </div>
  )
}

AccessKeyWrapper.propTypes = {
  label: PropTypes.string,
  desc: PropTypes.string,
  tooltipText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  refreshFunc: PropTypes.func,
  hideRefreshButton: PropTypes.bool,
  inputValue: PropTypes.string.isRequired,
  isRegeneratingNewKey: PropTypes.bool,
  regeneratingCredential: PropTypes.string,
  children: PropTypes.element.isRequired,
}

export default AccessKeyWrapper
