import { apiErrorParse } from './utils'
import { invokeLambda } from './invokeLambda'
// import { useErrorContext } from '../context/ErrorContext'
// import { useLoaderContext } from '../context/LoaderContext'

// const { setErrorMsg } = useErrorContext()
// const { setIsLoading } = useLoaderContext()

export async function customApi(
  setErrorMsg,
  setIsLoading,
  user,
  functionName,
  payload = undefined,
  setSuccessMsg = undefined,
) {
  try {
    setIsLoading(true)
    const response = await invokeLambda(user, functionName, payload)
    setIsLoading(false)

    if (response.status !== 200) {
      setErrorMsg(response.body)
    } else if (setSuccessMsg) {
      setSuccessMsg(response.body)
    }

    return response.body
  } catch (error) {
    setIsLoading(false)
    setErrorMsg(apiErrorParse(error))
  }
}
