import React from 'react'
import { MdErrorOutline } from 'react-icons/md'
import PropTypes from 'prop-types'

function TextArea({ placeholder, error, name, value, onChange, readOnly, maxLength }) {
  return (
    <div>
      <textarea
        rows={5}
        className={
          `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 shadow-sm pr-10` +
          `${readOnly ? ' cursor-pointer' : ''}`
        }
        placeholder={placeholder}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        maxLength={maxLength}
      />
      {value && error && (
        <span className="font-light text-xs text-[#F05A5A] flex items-center gap-1 mt-1">
          <MdErrorOutline size={18} />
          {error}
        </span>
      )}
    </div>
  )
}

TextArea.propTypes = {
  error: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
}

export default TextArea
