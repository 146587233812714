import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SectionTitle from '../../components/title/section-title'
import SecretsTabs from '../../components/tabs/SecretsTabs'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'

export default function Secrets() {
  const { t } = useTranslation()

  const [selectedTab, setSelectedTab] = useState(1)

  return (
    <>
      <div className="w-full container bg-white rounded-lg p-10">
        <SectionTitle title={t('secrets.heading')} description={t('secrets.subheading')} />
        <SecretsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </div>
      <div className="w-full flex justify-between items-center mt-4 mb-16">
        <Link to="/deployment" className="bg-white rounded-full p-4">
          <FaArrowLeft size={20} />
        </Link>
        <Link to="/access-keys" className="bg-white rounded-full p-4">
          <FaArrowRight size={20} />
        </Link>
      </div>
    </>
  )
}
