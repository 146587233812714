import React from 'react'
import { FileInput } from 'flowbite-react'
import { MdErrorOutline } from 'react-icons/md'
import PropTypes from 'prop-types'

function File({ onChange, error }) {
  return (
    <div>
      <div className="relative">
        <FileInput onChange={(e) => onChange(e.target.files)} />
        <div></div>
      </div>
      {error && (
        <span className="font-light text-xs text-[#F05A5A] flex items-center gap-1 mt-1">
          <MdErrorOutline size={18} />
          {error}
        </span>
      )}
    </div>
  )
}

File.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default File
