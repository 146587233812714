import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

// Define a component to handle RTL based on language
export function RTLWrapper({ children }) {
  const { i18n } = useTranslation()

  useEffect(() => {
    const direction = i18n.language === 'ar' ? 'rtl' : 'ltr'
    document.documentElement.dir = direction
  }, [i18n.language])

  return <>{children}</>
}

RTLWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}
