'use client'
import React from 'react'
import { Footer } from 'flowbite-react'
import { useTranslation } from 'react-i18next'

export default function FooterContainer() {
  const { t } = useTranslation()

  return (
    <Footer container className="rounded-none bg-transparent shadow-none border-0 flex flex-col pb-2 pt-0">
      <Footer.Divider style={{ margin: '14px' }} />
      <div className="w-full text-center">
        <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
          <div>
            <Footer.Copyright href="https://fill-easy.com/" by="Fill easy" year={2024} />
          </div>
          <Footer.LinkGroup className="flex items-end h-full">
            <Footer.Link href="#" className="text-[#475467]">
              {t('footer.privacy-policy')}
            </Footer.Link>
            <Footer.Link href="#" className="text-[#475467]">
              {t('footer.terms-and-conditions')}
            </Footer.Link>
            <Footer.Link href="/general-enquiries" className="text-[#475467]">
              {t('footer.contact-us')}
            </Footer.Link>
            <Footer.Link href="https://fill-easy.com/company/about-us.html" className="text-[#475467]">
              {t('footer.about-us')}
            </Footer.Link>
          </Footer.LinkGroup>
        </div>
      </div>
    </Footer>
  )
}
