import React, { useState, useEffect, useMemo } from 'react'
import TextWithTooltip from '../../components/input/text/text-with-tooltip/TextWithTooltip'
import TextInput from '../../components/input/text/text-input/TextInput'
import DropdownWithTooltip from '../../components/input/dropdown/dropdown-with-tooltip/DropdownWithTooltip'
import Dropdown from '../../components/input/dropdown/dropdown/Dropdown'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import { awsRegions } from '../../data/awsRegions'
import ConfirmationModalContainer from '../../components/modal/confirmation-dialog/Modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useUserContext } from '../../context/UserContext'
import { useErrorContext } from '../../context/ErrorContext'
import { validateOrganizationShorthand, deploy } from '../../api/deployment'
import SectionTitle from '../../components/title/section-title'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import { BottomNavButton } from './bottom-nav-button'
import Services from '../../components/services/Services'

const DEBOUNCE_TIME_MS = 300
const BANNED_STRINGS = ['singpass', 'corppass', 'myinfo']

export default function Deployment() {
  const { t } = useTranslation()

  const { user, deploymentStatus, setDeploymentStatus } = useUserContext()

  const { setErrorMsg } = useErrorContext()

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const [readOnly, setReadOnly] = useState(false)

  const [userCanDeploy, setUserCanDeploy] = useState(false)

  const [companyShorthandError, setCompanyShorthandError] = useState(null)

  const doesNotContainBannedSubstrings = (value) => {
    if (!value) return true // Skip validation if the value is empty or undefined
    return !BANNED_STRINGS.some((substring) => value.includes(substring))
  }

  const schema = Yup.object().shape({
    companyDisplayName: Yup.string().required(t('error-messages.required-field')).min(3, t('error-messages.min-3')),
    companyShorthand: Yup.string()
      .required(t('error-messages.required-field'))
      .min(3, t('error-messages.min-3'))
      .matches(/^[a-z0-9-]*$/, t('error-messages.invalid-company-shorthand'))
      .test(
        'does-not-contain-forbidden-substrings',
        `${t('error-messages.banned-strings')}: ${BANNED_STRINGS.join(', ')}`,
        doesNotContainBannedSubstrings,
      ),
    awsRegion: Yup.string().required(t('error-messages.required-field')),
  })

  const formik = useFormik({
    initialValues: {
      companyDisplayName: user?.attributes['custom:organization-full'] || '',
      companyShorthand: user?.attributes['custom:organization'] || '',
      awsRegion: user?.attributes['custom:region'] || awsRegions[0].value,
    },
    validationSchema: schema,

    onSubmit: async () => {
      if (!checkIfValidationRulesPassed({ errors, values }) || !userCanDeploy) {
        setShowConfirmationModal(false)
      } else {
        setShowConfirmationModal(true)
      }
    },
  })

  const { errors, values, handleChange, handleSubmit } = formik

  const onOrganizationShorthandChange = useMemo(() => {
    return debounce(async (value) => {
      if (!userCanDeploy) return

      const responseMessage = await validateOrganizationShorthand(
        {
          organization: value,
        },
        setErrorMsg,
      )
      setCompanyShorthandError(responseMessage)
    }, DEBOUNCE_TIME_MS)
  }, [userCanDeploy])

  useEffect(() => {
    return () => {
      onOrganizationShorthandChange.cancel()
    }
  }, [onOrganizationShorthandChange])

  useEffect(() => {
    setUserCanDeploy(deploymentStatus.canDeploy)
    setReadOnly(['deployed', 'deploying'].includes(deploymentStatus.status))
  }, [deploymentStatus])

  const checkIfValidationRulesPassed = ({ errors, values }) => {
    if (!values) return false
    if (!values.companyDisplayName || !values.companyShorthand || !values.awsRegion) {
      return false
    }
    if (errors.companyDisplayName || errors.companyShorthand || errors.awsRegion || companyShorthandError) {
      return false
    }
    return true
  }

  const handleDeployInfra = async () => {
    setShowConfirmationModal(false)

    setDeploymentStatus({
      status: 'deploying',
      canDeploy: false,
      steps: {
        createAccount: 'waiting',
        deployStack: 'waiting',
        updateDns: 'waiting',
      },
    })

    return await deploy(setErrorMsg, {
      region: values.awsRegion,
      organization: values.companyShorthand,
      organizationFull: values.companyDisplayName,
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="w-full container bg-white rounded-lg p-10">
        <SectionTitle title={t('deployment.heading')} description={t('deployment.subheading')} />
        <TextWithTooltip
          label={t('deployment.form.organization-full-label')}
          tooltipText={t('deployment.form.organization-full-tooltip-text')}
          name="companyDisplayName"
        >
          <TextInput
            placeholder={t('deployment.form.organization-full-placeholder')}
            defaultInputVisibility={true}
            hideVisibilityIcon={true}
            name="companyDisplayName"
            value={values.companyDisplayName}
            onChange={handleChange}
            error={errors.companyDisplayName ? errors.companyDisplayName : ''}
            readOnly={readOnly}
            maxLength={64}
          />
        </TextWithTooltip>
        <TextWithTooltip
          label={t('deployment.form.organization-short-label')}
          tooltipText={t('deployment.form.organization-short-tooltip-text')}
          name="companyShorthand"
        >
          <TextInput
            placeholder={t('deployment.form.organization-short-placeholder')}
            defaultInputVisibility={true}
            hideVisibilityIcon={true}
            name="companyShorthand"
            value={values.companyShorthand}
            showCheckmark={companyShorthandError === ''}
            onChange={(e) => {
              handleChange(e)
              onOrganizationShorthandChange(e.target.value)
            }}
            error={
              companyShorthandError ? companyShorthandError : errors.companyShorthand ? errors.companyShorthand : ''
            }
            readOnly={readOnly}
            maxLength={20}
          />
        </TextWithTooltip>
        <DropdownWithTooltip
          label={t('deployment.form.region-label')}
          tooltipText={t('deployment.form.region-tooltip-text')}
          name="awsRegion"
        >
          <Dropdown
            options={awsRegions}
            name="awsRegion"
            value={values.awsRegion}
            onChange={handleChange}
            error={errors.awsRegion ? errors.awsRegion : ''}
            readOnly={readOnly}
          />
        </DropdownWithTooltip>
        <div className="mb-4 mt-10 w-full  flex justify-center">
          <PrimaryButton
            text={t('deployment.form.deploy-button')}
            type="submit"
            isDisabled={!checkIfValidationRulesPassed({ errors, values }) || !userCanDeploy || readOnly}
            tooltipText={
              readOnly
                ? t('already-deployed')
                : !checkIfValidationRulesPassed({ errors, values })
                  ? t('deployment.form.errors')
                  : t('cant-deploy')
            }
          />
        </div>
        <div className="w-full flex justify-center">
          <Services />
        </div>
      </form>
      <BottomNavButton />

      <ConfirmationModalContainer
        openModal={showConfirmationModal}
        setOpenModal={setShowConfirmationModal}
        modalHeader={t('deployment.modal.heading')}
        modalBody={t('deployment.modal.body')}
        modalFooter={
          <div className="w-full flex justify-between items-center">
            <PrimaryButton
              text={t('deployment.modal.deploy-button')}
              type="button"
              onClick={() => handleDeployInfra()}
            />
            <SecondaryButton text={t('deployment.modal.cancel')} onClick={() => setShowConfirmationModal(false)} />
          </div>
        }
        isDisabled={!checkIfValidationRulesPassed({ errors, values }) || !userCanDeploy}
      />
    </>
  )
}
