import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import SectionTitle from '../../components/title/section-title'
import { FaArrowLeft } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import { customApi } from '../../api/awsConnector'
import { useSuccessContext } from '../../context/SuccessContext'
import { useUserContext } from '../../context/UserContext'
import { useErrorContext } from '../../context/ErrorContext'
import { useLoaderContext } from '../../context/LoaderContext'
import Ips from '../../components/ip-whitelisting/Ips'
import PrimaryButton from '../../components/buttons/PrimaryButton'

export default function IpWhitelisting() {
  const { t } = useTranslation()

  const { user } = useUserContext()
  const { setSuccessMsg } = useSuccessContext()

  const { setErrorMsg } = useErrorContext()

  const { setIsLoading } = useLoaderContext()

  useEffect(() => {
    if (user) {
      customApi(setErrorMsg, setIsLoading, user, 'portal-getips').then((res) => {
        if (typeof res === 'string') {
          res = [res]
        }
        let response = [...res]
        while (response.length < 4) {
          response.push('')
        }
        setIps(response)
      })
    }
  }, [])

  const [ips, setIps] = useState(['', '', '', ''])

  const handleSaveIps = (ips) => {
    const payload = [...ips].filter((ip) => ip !== '' && ip !== null && ip !== undefined)
    customApi(setErrorMsg, setIsLoading, user, 'portal-putips', payload, setSuccessMsg)
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <>
      <div className="w-full container bg-white rounded-lg p-10">
        <SectionTitle title={t('ipwhitelisting.heading')} description={t('ipwhitelisting.subheading')} />
        <Ips ips={ips} setIps={setIps} t={t} />
        <button
          onClick={() => setIps((prev) => [...prev, ''])}
          className="mt-4 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-200 rounded shadow-sm text-sm"
        >
          {t('ipwhitelisting.add-more')}
        </button>
        <div className="w-full flex justify-center mt-4">
          <PrimaryButton text={t('save')} onClick={() => handleSaveIps(ips)} />
        </div>
      </div>
      <div className="w-full flex items-center mt-4 mb-16">
        <Link to="/access-keys" className="bg-white rounded-full p-4">
          <FaArrowLeft size={20} />
        </Link>
      </div>
    </>
  )
}
