import { CognitoIdentityClient, GetCredentialsForIdentityCommand, GetIdCommand } from '@aws-sdk/client-cognito-identity'
import { apiErrorParse } from './utils'
import { InvokeCommand, LambdaClient } from '@aws-sdk/client-lambda'

const identityClient = new CognitoIdentityClient({ region: 'us-east-1' })

const encoder = new TextEncoder()

let lambdaClient = null
let cachedCredentials = null
let credentialsExpiration = null

export async function getAWSCredentials(user) {
  if (cachedCredentials && credentialsExpiration && new Date() < credentialsExpiration) {
    return cachedCredentials
  }

  const idToken = localStorage.getItem(`${user.keyPrefix}.${user.username}.idToken`)
  const logins = {
    [`cognito-idp.us-east-1.amazonaws.com/${user.pool.userPoolId}`]: idToken,
  }

  if (!user.attributes['custom:identitypool-id']) {
    throw new Error("User doesn't have an Identity Pool")
  }
  try {
    // Get Identity ID
    const identityResponse = await identityClient.send(
      new GetIdCommand({
        IdentityPoolId: user.attributes['custom:identitypool-id'],
        Logins: logins,
      }),
    )

    // Get AWS Credentials
    const credentialsResponse = await identityClient.send(
      new GetCredentialsForIdentityCommand({
        IdentityId: identityResponse.IdentityId,
        Logins: logins,
      }),
    )

    const { AccessKeyId, SecretKey, SessionToken, Expiration } = credentialsResponse.Credentials
    cachedCredentials = {
      accessKeyId: AccessKeyId,
      secretAccessKey: SecretKey,
      sessionToken: SessionToken,
    }
    credentialsExpiration = new Date(Expiration)

    // Update the lambda client with new credentials
    lambdaClient = new LambdaClient({
      region: user.attributes['custom:deployed-region'],
      credentials: cachedCredentials,
    })

    return cachedCredentials
  } catch (error) {
    throw new Error(apiErrorParse(error))
  }
}

export async function invokeLambda(user, name, payload) {
  // Ensure we have valid credentials
  if (!cachedCredentials || new Date() >= credentialsExpiration) {
    await getAWSCredentials(user)
  }

  const invokeParams = {
    FunctionName: name,
    InvocationType: 'RequestResponse',
  }

  if (payload !== undefined) {
    invokeParams.Payload = encoder.encode(JSON.stringify(payload))
  }

  const response = await lambdaClient.send(new InvokeCommand(invokeParams))

  const resPayload = JSON.parse(new TextDecoder('utf-8').decode(response.Payload))

  return resPayload
}
