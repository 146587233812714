import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getDeploymentStatus } from '../api/deployment'
import { useErrorContext } from './ErrorContext'
import { useLoaderContext } from './LoaderContext'
import { fetchUser } from '../api/user'

const UserContext = createContext()

const UserProvider = ({ children }) => {
  const { setErrorMsg } = useErrorContext()
  const { setIsLoading } = useLoaderContext()

  const [user, setUser] = useState(undefined)
  const [deploymentStatus, setDeploymentStatus] = useState({})
  const [deploymentEnv, setDeploymentEnv] = useState(null)

  useEffect(() => {
    fetchUser(setErrorMsg, setIsLoading).then((userData) => setUser(userData))
  }, [])

  useEffect(() => {
    if (user) {
      getDeploymentStatus(setErrorMsg).then((status) => {
        setDeploymentStatus(status)
      })
    }
  }, [user])

  const isDeployed = deploymentStatus.status === 'deployed'

  const value = {
    user,
    setUser,
    deploymentStatus,
    setDeploymentStatus,
    deploymentEnv,
    setDeploymentEnv,
    isDeployed,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserContext = () => useContext(UserContext)

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default UserProvider
