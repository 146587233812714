import React, { useState } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { BiShow, BiHide } from 'react-icons/bi'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import PropTypes from 'prop-types'

function TextInput({
  placeholder,
  error,
  defaultInputVisibility,
  hideVisibilityIcon,
  name,
  value,
  onChange,
  showCheckmark,
  readOnly,
  maxLength,
}) {
  const [visible, setVisible] = useState(defaultInputVisibility)

  return (
    <div>
      <div className="relative">
        {!hideVisibilityIcon && (
          <div className="absolute right-3 inset-y-0 flex items-center ps-3.5">
            {visible ? (
              <button type="button" onClick={() => setVisible(false)}>
                <BiHide size={18} />
              </button>
            ) : (
              <button type="button" onClick={() => setVisible(true)}>
                <BiShow size={18} />
              </button>
            )}
          </div>
        )}
        {showCheckmark && (
          <div className="absolute right-3 inset-y-0 flex items-center ps-3.5">
            <IoIosCheckmarkCircle size={18} className="text-green-400" />
          </div>
        )}
        <input
          type={visible ? 'text' : 'password'}
          className={
            `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-sm pr-10` +
            `${readOnly ? ' cursor-pointer' : ''}`
          }
          placeholder={placeholder}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          maxLength={maxLength}
          autoComplete="off"
        />
      </div>
      {value && error && (
        <span className="font-light text-xs text-[#F05A5A] flex items-center gap-1 mt-1">
          <MdErrorOutline size={18} />
          {error}
        </span>
      )}
    </div>
  )
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  error: PropTypes.string,
  defaultInputVisibility: PropTypes.bool,
  hideVisibilityIcon: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  showCheckmark: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
}

export default TextInput
