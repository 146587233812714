'use client'
import { Table } from 'flowbite-react'
import React from 'react'
import PropTypes from 'prop-types'
import { BiChevronLeft } from 'react-icons/bi'
import { BiChevronRight } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'

function BillingTable({ month, getPreviousMonthData, getNextMonthData, tableData, uniqueKeysArray, currentMonth }) {
  const { t } = useTranslation()
  const formatMonth = (month) => {
    if (!month) return ''
    const [year, monthNumber] = month.split('-').map(Number)
    const date = new Date(year, monthNumber - 1)
    const monthName = date.toLocaleString('en-US', { month: 'long' })
    return `${monthName}, ${year}`
  }

  return (
    <div className="w-full">
      <div>
        <MonthData
          month={month}
          getPreviousMonthData={getPreviousMonthData}
          getNextMonthData={getNextMonthData}
          formatMonth={formatMonth}
          currentMonth={currentMonth}
        />
      </div>
      <div className="overflow-x-auto w-full bg-white rounded-2xl p-8 text-center">
        {tableData.length > 0 ? (
          <Table hoverable className="w-full">
            <Table.Head className="bg-transparent text-[#71717A] border-b pb-6">
              {uniqueKeysArray.map((key, index) => (
                <Table.HeadCell
                  key={index}
                  className="bg-white capitalize font-medium text-[16px] w-[200px] text-center"
                >
                  {key}
                </Table.HeadCell>
              ))}
            </Table.Head>
            <Table.Body className="border-b divide-y text-gray-700">
              {tableData.map((item, index) => (
                <Table.Row key={index}>
                  {uniqueKeysArray.map((key, index) => (
                    <Table.Cell key={index} className="text-center">
                      {item[key]}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <span>{t('billing.no-requests')}</span>
        )}
      </div>
    </div>
  )
}

function MonthData({ month, getPreviousMonthData, getNextMonthData, formatMonth, currentMonth }) {
  const isCurrentMonth = month === currentMonth

  return (
    <>
      <div className="flex items-center justify-between py-8 px-4 w-full">
        <button
          onClick={getPreviousMonthData}
          className="bg-white border-2 border-gray-100 flex items-center justify-center h-8 w-8 text-black font-bold rounded"
        >
          <BiChevronLeft />
        </button>
        <span className="text-lg font-medium">{formatMonth(month)}</span>
        <button
          onClick={getNextMonthData}
          disabled={isCurrentMonth}
          className={`bg-white border-2 border-gray-100 flex items-center justify-center h-8 w-8 text-black font-bold rounded ${
            isCurrentMonth ? 'cursor-not-allowed opacity-50' : ''
          }`}
        >
          <BiChevronRight />
        </button>
      </div>
    </>
  )
}

BillingTable.propTypes = {
  month: PropTypes.string,
  currentMonth: PropTypes.string,
  getPreviousMonthData: PropTypes.func,
  getNextMonthData: PropTypes.func,
  tableData: PropTypes.array,
  uniqueKeysArray: PropTypes.array,
}

MonthData.propTypes = {
  month: PropTypes.string,
  currentMonth: PropTypes.string,
  getPreviousMonthData: PropTypes.func,
  getNextMonthData: PropTypes.func,
  formatMonth: PropTypes.func,
}

export default BillingTable
