'use client'
import { Navbar } from 'flowbite-react'
import React from 'react'
import { IoLogOut } from 'react-icons/io5'
import { GoDotFill } from 'react-icons/go'
import { useUserContext } from '../../context/UserContext'
import { withAuthenticator } from '@aws-amplify/ui-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DefaultTooltip } from '../tooltips/FETooltips'

function Topbar({ signOut }) {
  const { t } = useTranslation()
  const { user, deploymentStatus } = useUserContext()

  const handleLogout = () => {
    signOut()
  }

  const env = user.attributes['custom:env'] ?? 'guest'

  return (
    <div style={{ zIndex: '10' }} className="absolute top-0 right-0 left-0">
      <Navbar className="border-b-4" fluid>
        <div className="flex items-center justify-start p-2 mr-2">
          <GoDotFill className={deploymentStatus.status === 'deployed' ? 'text-green-500' : 'text-red-500'} />
          <span className="text-gray-700 text-sm font-semibold ml-1">
            {deploymentStatus.status ? t(`topbar.${deploymentStatus.status}`) : '...'}
          </span>
        </div>
        <div
          className="ml-4 text-white text-sm font-semibold p-2 w-max rounded-lg"
          style={{
            backgroundColor: env === 'prod' ? '#FE3737' : '#66A3FF',
          }}
        >
          {env ? t(`topbar.${env}`) : '...'}
        </div>
        <div className="flex items-center ml-auto bg-slate-200 font-medium text-gray-700 px-2 rounded-md">
          {user?.attributes?.email}
          <button onClick={handleLogout} className="flex p-2 items-center text-red-600 bg-slate-200 text-lg">
            <DefaultTooltip text={t('topbar.logout')}>
              <IoLogOut />
            </DefaultTooltip>
          </button>
        </div>
      </Navbar>
    </div>
  )
}

Topbar.propTypes = {
  signOut: PropTypes.func,
}

export default withAuthenticator(Topbar)
