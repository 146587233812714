import React from 'react'
import PropTypes from 'prop-types'
import { TooltipWithIcon } from '../../../tooltips/FETooltips'

function TextWithTooltip({ label, tooltipText, name, children }) {
  return (
    <div className="mb-8">
      <div className="mb-2 text-sm font-normal text-gray-900 dark:text-white flex items-center gap-2">
        <label htmlFor={name}>{label}</label>
        <TooltipWithIcon text={tooltipText} />
      </div>
      {children}
    </div>
  )
}

TextWithTooltip.propTypes = {
  label: PropTypes.string,
  tooltipText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

export default TextWithTooltip
