import React, { useEffect } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Auth, Hub } from 'aws-amplify'
import { useUserContext } from '../../context/UserContext'
import { useNavigate } from 'react-router-dom'

function Authentication() {
  const { user, setUser } = useUserContext()
  const navigate = useNavigate()

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setUser(data)
          break
        case 'signOut':
          setUser(undefined)
          break
        // Add here other cases like 'signOut', 'signIn_failure', etc. if needed
      }
    })

    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(() => console.log('Not signed in'))

    return () => Hub.remove('auth')
  }, [])

  useEffect(() => {
    if (user) {
      navigate('/deployment')
    }
  }, [user])

  return <div className="w-screen h-screen bg-white"></div>
}

const formFields = {
  setupTOTP: {
    QR: {
      totpIssuer: 'Fill Easy Portal',
      // totpUsername: user.username,
    },
  },
}

export default withAuthenticator(Authentication, { formFields })
