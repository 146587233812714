import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import SectionTitle from '../../components/title/section-title'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '../../context/UserContext'
import { useErrorContext } from '../../context/ErrorContext'
import { useLoaderContext } from '../../context/LoaderContext'
import AccessKeyWrapper from '../../components/input/access-key-wrapper/AccessKeyWrapper'
import TextInput from '../../components/input/text/text-input/TextInput'
import AccessKeyTextArea from '../../components/input/textarea/access-key-textarea/AccessKeyTextArea'
import ConfirmationModalContainer from '../../components/modal/confirmation-dialog/Modal'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import { customApi } from '../../api/awsConnector'

export default function AccessKeys() {
  const { t } = useTranslation()

  const { user } = useUserContext()

  const { setErrorMsg } = useErrorContext()

  const { setIsLoading } = useLoaderContext()

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const [credentialToRotate, setCredentialToRotate] = useState('')

  const [isRegeneratingNewKey, setIsRegeneratingNewKey] = useState(false)

  const [accessKeys, setAccessKeys] = useState({
    'base-url': '',
    'x-client-id': '',
    'x-client-secret': '',
    jwt: '',
    jwe: '',
  })

  useEffect(() => {
    if (user) {
      customApi(setErrorMsg, setIsLoading, user, 'portal-getcredentials').then((res) => {
        setAccessKeys(res)
      })
    }
  }, [user])

  const rotateAccessKey = async (credential) => {
    setShowConfirmationModal(false)
    if (credential) {
      setIsRegeneratingNewKey(true)
      await customApi(setErrorMsg, setIsLoading, user, 'portal-rotatecredential', { credential })

      let accessKeyResponse = await customApi(setErrorMsg, setIsLoading, user, 'portal-getcredentials')
      setAccessKeys(accessKeyResponse)

      setIsRegeneratingNewKey(false)
    }
  }

  return (
    <>
      <div className="w-full container bg-white rounded-lg p-10">
        <SectionTitle title={t('accesskeys.heading')} description={t('accesskeys.subheading')} />
        <AccessKeyWrapper
          label={t('accesskeys.form.baseurl.label')}
          tooltipText={t('accesskeys.form.baseurl.tooltip-text')}
          name="baseurl"
          inputValue={accessKeys['base-url']}
          hideRefreshButton={true}
        >
          <TextInput
            defaultInputVisibility={true}
            hideVisibilityIcon={true}
            name="baseurl"
            value={accessKeys['base-url']}
            readOnly={true}
          />
        </AccessKeyWrapper>

        <AccessKeyWrapper
          label={t('accesskeys.form.clientid.label')}
          tooltipText={t('accesskeys.form.clientid.tooltip-text')}
          name="x-client-id"
          inputValue={accessKeys['x-client-id']}
          refreshFunc={() => {
            setShowConfirmationModal(true)
            setCredentialToRotate('x-client-id')
          }}
          isRegeneratingNewKey={isRegeneratingNewKey}
          regeneratingCredential={credentialToRotate}
        >
          <TextInput
            defaultInputVisibility={false}
            hideVisibilityIcon={false}
            name="x-client-id"
            value={accessKeys['x-client-id']}
            readOnly={true}
          />
        </AccessKeyWrapper>

        <AccessKeyWrapper
          label={t('accesskeys.form.clientsecret.label')}
          tooltipText={t('accesskeys.form.clientsecret.tooltip-text')}
          name="x-client-secret"
          inputValue={accessKeys['x-client-secret']}
          refreshFunc={() => {
            setShowConfirmationModal(true)
            setCredentialToRotate('x-client-secret')
          }}
          isRegeneratingNewKey={isRegeneratingNewKey}
          regeneratingCredential={credentialToRotate}
        >
          <TextInput
            defaultInputVisibility={false}
            hideVisibilityIcon={false}
            name="x-client-secret"
            value={accessKeys['x-client-secret']}
            readOnly={true}
          />
        </AccessKeyWrapper>

        <AccessKeyWrapper
          label={t('accesskeys.form.jwe.label')}
          tooltipText={t('accesskeys.form.jwe.tooltip-text')}
          inputValue={accessKeys['jwe']}
          refreshFunc={() => {
            setShowConfirmationModal(true)
            setCredentialToRotate('jwe')
          }}
          isRegeneratingNewKey={isRegeneratingNewKey}
          regeneratingCredential={credentialToRotate}
          name="jwe"
        >
          <AccessKeyTextArea value={accessKeys['jwe']} />
        </AccessKeyWrapper>

        <AccessKeyWrapper
          label={t('accesskeys.form.jwt.label')}
          tooltipText={t('accesskeys.form.jwt.tooltip-text')}
          inputValue={accessKeys['jwt']}
          refreshFunc={() => {
            setShowConfirmationModal(true)
            setCredentialToRotate('jwt')
          }}
          isRegeneratingNewKey={isRegeneratingNewKey}
          regeneratingCredential={credentialToRotate}
          name="jwt"
        >
          <AccessKeyTextArea value={accessKeys['jwt']} />
        </AccessKeyWrapper>
      </div>

      <ConfirmationModalContainer
        openModal={showConfirmationModal}
        setOpenModal={setShowConfirmationModal}
        modalHeader={t('accesskeys.modal.heading')}
        modalBody={t('accesskeys.modal.body')}
        modalFooter={
          <div className="w-full flex justify-between items-center">
            <PrimaryButton
              text={t('accesskeys.modal.proceed-button')}
              type="button"
              onClick={() => rotateAccessKey(credentialToRotate)}
            />
            <SecondaryButton text={t('accesskeys.modal.cancel')} onClick={() => setShowConfirmationModal(false)} />
          </div>
        }
      />

      <div className="w-full flex justify-between items-center mt-4 mb-16">
        <Link to="/secrets" className="bg-white rounded-full p-4">
          <FaArrowLeft size={20} />
        </Link>
        <Link to="/ip-whitelisting" className="bg-white rounded-full p-4">
          <FaArrowRight size={20} />
        </Link>
      </div>
    </>
  )
}
