import React from 'react'
import { FaArrowRight } from 'react-icons/fa6'
import { useUserContext } from '../../context/UserContext'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DefaultTooltip } from '../../components/tooltips/FETooltips'

export function BottomNavButton() {
  const { t } = useTranslation()
  const { isDeployed } = useUserContext()

  return (
    <div className="w-full flex justify-end mt-4 mb-16">
      {isDeployed ? (
        <Link to="/secrets" className="bg-white rounded-full p-4 mr-4">
          <FaArrowRight size={20} />
        </Link>
      ) : (
        <DefaultTooltip text={t('not-deployed')}>
          <div className="bg-white rounded-full p-4 cursor-not-allowed">
            <FaArrowRight size={20} />
          </div>
        </DefaultTooltip>
      )}
    </div>
  )
}
