import React from 'react'
import { Spinner } from 'flowbite-react'
import { IoMdCheckmark } from 'react-icons/io'
import { MdOutlineCancel } from 'react-icons/md'
import PropTypes from 'prop-types'

const StatusIndicator = ({ status, text }) => {
  const getStatusIcon = () => {
    switch (status) {
      case 'running':
        return <Spinner size="sm" color="success" />
      case 'succeeded':
        return <IoMdCheckmark size={20} className="text-green-600" />
      case 'failed':
        return <MdOutlineCancel size={20} className="text-red-600" />
      case 'waiting':
      default:
        return <div className="w-4 h-4 border border-gray-400 rounded-full bg-gray-200" />
    }
  }

  return (
    <div className="flex items-center gap-2 py-2">
      <div className="flex-shrink-0">{getStatusIcon()}</div>
      <span className="text-sm text-grey-900">{text}</span>
    </div>
  )
}

StatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default StatusIndicator
