import React, { useState, useEffect } from 'react'
import BillingTable from '../../components/tables/billing/BillingTable'
import { useErrorContext } from '../../context/ErrorContext'
import { useLoaderContext } from '../../context/LoaderContext'
import { useUserContext } from '../../context/UserContext'
import { customApi } from '../../api/awsConnector'

export default function Billing() {
  const { setErrorMsg } = useErrorContext()
  const { user } = useUserContext()

  const { setIsLoading } = useLoaderContext()

  const [month, setMonth] = useState()

  const [currentMonth, setCurrentMonth] = useState()

  const [tableData, setTableData] = useState([])

  const [uniqueKeysArray, setUniqueKeysArray] = useState([])

  useEffect(() => {
    const getCurrentMonth = () => {
      const now = new Date()
      const year = now.getFullYear()
      const month = (now.getMonth() + 1).toString().padStart(2, '0')
      return `${year}-${month}`
    }

    const currentMonth = getCurrentMonth()
    setMonth(currentMonth)
    setCurrentMonth(currentMonth)
  }, [])

  useEffect(() => {
    if (month) {
      customApi(setErrorMsg, setIsLoading, user, 'portal-getbilling', { month }).then((response) => {
        setTableData(response)
      })
    }
  }, [month])

  useEffect(() => {
    if (tableData.length > 0) {
      setUniqueKeysArray(Object.keys(tableData[0] || {}))
    }
  }, [month, tableData])

  const getPreviousMonth = () => {
    const [year, currentMonth] = month.split('-').map(Number)
    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1
    const previousYear = currentMonth === 1 ? year - 1 : year
    setMonth(`${previousYear}-${previousMonth.toString().padStart(2, '0')}`)
  }

  const getNextMonth = () => {
    const [year, currentMonth] = month.split('-').map(Number)
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1
    const nextYear = currentMonth === 12 ? year + 1 : year
    setMonth(`${nextYear}-${nextMonth.toString().padStart(2, '0')}`)
  }

  return (
    <div className="container flex flex-wrap gap-4 w-full">
      <BillingTable
        tableData={tableData}
        month={month}
        getPreviousMonthData={getPreviousMonth}
        getNextMonthData={getNextMonth}
        uniqueKeysArray={uniqueKeysArray}
        currentMonth={currentMonth}
      />
    </div>
  )
}
