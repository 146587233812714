import React from 'react'
import PropTypes from 'prop-types'
import { TooltipWithIcon } from '../../../tooltips/FETooltips'

function DropdownWithTooltip({ label, tooltipText, name, children }) {
  return (
    <>
      <div className="mb-8 ">
        <label htmlFor={name} className="mb-2 text-sm font-normal text-gray-900 flex items-center gap-2">
          <span>{label}</span>
          <TooltipWithIcon text={tooltipText} />
        </label>
        {children}
      </div>
    </>
  )
}

DropdownWithTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

export default DropdownWithTooltip
