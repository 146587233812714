export const apiErrorParse = (error) => {
  console.log({ error })

  if (error.response) {
    return typeof error.response.data === 'string'
      ? error.response.data
      : JSON.stringify(error.response.data.message || error.response.data)
  }

  if (error.message) {
    return error.message
  }

  if (error.request) {
    return 'No response received from server.'
  }

  return 'Error in setting up the request.'
}
