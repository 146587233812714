import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSuccessContext } from '../../../context/SuccessContext'
import { useErrorContext } from '../../../context/ErrorContext'
import LoadSpinner from '../../spinner/Spinner'
import { encodeFile } from '../encodeFile'
import * as Yup from 'yup'
import { useUserContext } from '../../../context/UserContext'
import { customApi } from '../../../api/awsConnector'
import Form from '../Form'

function IAmSmartTab() {
  const { t } = useTranslation()
  const { setErrorMsg } = useErrorContext()
  const { setSuccessMsg } = useSuccessContext()
  const { user } = useUserContext()
  const [isLoading, setIsLoading] = useState(false)

  const [currentIamsmartValues, setCurrentIamsmartValues] = useState({
    iamsmartClientId: '',
    iamsmartClientSecret: '',
    p12Passcode: '',
    p12Certificate: null,
  })

  const initialValues = {
    iamsmartClientId: '',
    iamsmartClientSecret: '',
    p12Passcode: '',
    p12Certificate: null,
  }

  const validationSchema = {
    iamsmartClientId: Yup.string()
      .required(t('error-messages.required-field'))
      .length(32, t('error-messages.length-32')),
    iamsmartClientSecret: Yup.string()
      .required(t('error-messages.required-field'))
      .length(32, t('error-messages.length-32')),
    p12Passcode: Yup.string().required(t('error-messages.required-field')).length(16, t('error-messages.length-16')),
    p12Certificate: Yup.mixed()
      .required(t('error-messages.required-field'))
      .test(
        'fileFormat',
        t('error-messages.p12'),
        (value) => value && (value.type === 'application/x-pkcs12' || value.name.endsWith('.p12')),
      ),
  }

  const onSubmit = async ({ iamsmartClientId, iamsmartClientSecret, p12Passcode, p12Certificate }) => {
    let payload

    if (p12Certificate) {
      const p12B64 = await encodeFile(p12Certificate)
      payload = { iamsmartClientId, iamsmartClientSecret, p12Passcode, p12B64 }
    } else {
      payload = { iamsmartClientId, iamsmartClientSecret, p12Passcode }
    }
    await customApi(
      setErrorMsg,
      setIsLoading,
      user,
      'portal-putsecrets',
      { eid: 'iamsmart', body: payload },
      setSuccessMsg,
    )

    customApi(setErrorMsg, setIsLoading, user, 'portal-getsecrets', { eid: 'iamsmart' }).then((res) => {
      setCurrentIamsmartValues(res)
    })
  }

  useEffect(() => {
    setIsLoading(true)
    customApi(setErrorMsg, setIsLoading, user, 'portal-getsecrets', { eid: 'iamsmart' }).then((response) => {
      setCurrentIamsmartValues({ ...response, p12Certificate: null })
    })
    setIsLoading(false)
  }, [setErrorMsg])

  const fields = [
    {
      name: 'iamsmartClientId',
      label: 'Client ID',
      placeholder: 'a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p',
      modalBodyContent: (
        <div className="p-5 pt-0">
          <p>{t('secrets.iamsmart.form.client-id-modal-content')}</p>
          <img
            src="https://images.pexels.com/photos/38568/apple-imac-ipad-workplace-38568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
            className="mt-10 px-10"
          />
        </div>
      ),
      type: 'text',
    },
    {
      name: 'iamsmartClientSecret',
      label: 'Client Secret',
      placeholder: 'a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p',
      modalBodyContent: (
        <div className="p-5 pt-0">
          <p>{t('secrets.iamsmart.form.client-secret-modal-content')}</p>
          <img
            src="https://images.pexels.com/photos/38568/apple-imac-ipad-workplace-38568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
            className="mt-10 px-10"
          />
        </div>
      ),
      type: 'text',
    },
    {
      name: 'p12Passcode',
      label: 'P12 Passcode',
      placeholder: 'a1b2c3d4e5f6g7h8',
      modalBodyContent: (
        <div className="p-5 pt-0">
          <p>{t('secrets.iamsmart.form.p12-passcode-modal-content')}</p>
          <img
            src="https://images.pexels.com/photos/38568/apple-imac-ipad-workplace-38568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
            className="mt-10 px-10"
          />
        </div>
      ),
      type: 'text',
    },
    {
      name: 'p12Certificate',
      label: 'P12 Certificate',
      modalBodyContent: (
        <div className="p-5 pt-0">
          <p>{t('secrets.iamsmart.form.p12-certificate-modal-content')}</p>
          <img
            src="https://images.pexels.com/photos/38568/apple-imac-ipad-workplace-38568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
            className="mt-10 px-10"
          />
        </div>
      ),
      type: 'file',
    },
  ]

  return (
    <>
      {isLoading ? (
        <LoadSpinner />
      ) : (
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          currentValues={currentIamsmartValues}
          fields={fields}
          service="iamsmart"
        />
      )}
    </>
  )
}

export default IAmSmartTab
