import { API } from 'aws-amplify'
import { apiErrorParse } from './utils'

/**
 * POST /slack/general
 * description: Submit General Enquiry
 * @param {object} payload
 * payload = {
 * subject: 'Subject'.
 * message: 'A long string which details the request'.
 * }
 * @param {function} setGlobalErrorMessage - this is setErrorMsg from useErrorContext
 * @param {function} setGlobalLoader - this is setIsLoading from useLoaderContext
 * @returns ???
 */
export async function slackMessage(payload, setGlobalErrorMessage, setGlobalLoader) {
  try {
    setGlobalLoader(true)
    const response = await API.post('api', `/slack/general`, {
      body: payload,
    })
    setGlobalLoader(false)
    return response
  } catch (error) {
    setGlobalLoader(false)
    setGlobalErrorMessage(apiErrorParse(error))
  }
}
