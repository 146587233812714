import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Sidebar } from 'flowbite-react'
import { DefaultTooltip } from '../tooltips/FETooltips'
import { Link } from 'react-router-dom'
import { FaLock } from 'react-icons/fa'

SidebarLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isAccessible: PropTypes.bool.isRequired,
}

function SidebarLink({ to, label, isActive, isAccessible }) {
  const { t } = useTranslation()
  const baseClasses = 'text-[#475467] text-sm hover:font-semibold hover:text-black transition-all ease-in'
  const activeClasses = isActive ? 'text-black font-semibold bg-slate-200' : ''
  const disabledClasses = isAccessible ? '' : 'cursor-not-allowed text-gray-400'
  const classes = `${baseClasses} ${activeClasses} ${disabledClasses}`

  const handleClick = (event) => {
    if (!isAccessible) {
      event.preventDefault() // Prevent the default action if not accessible
    }
  }

  const item = (
    <Sidebar.Item className={classes} onClick={handleClick}>
      <Link
        to={to}
        className={`flex items-center ${isAccessible ? '' : 'cursor-not-allowed text-gray-400'}`}
        onClick={handleClick}
      >
        {!isAccessible && <FaLock className="mr-2" />} {/* Add lock icon */}
        {label}
      </Link>
    </Sidebar.Item>
  )

  return isAccessible ? item : <DefaultTooltip text={t('not-deployed')}>{item}</DefaultTooltip>
}

export default SidebarLink
