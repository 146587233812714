import React, { useState } from 'react'
import Modal from '../../../modal/learn-more/Modal'
import PropTypes from 'prop-types'

function FileUploadWithModal({ label, modalHeaderContent, modalBodyContent, modalFooterContent, children }) {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <div className="mb-8">
        <div className="mb-2 text-sm font-normal text-gray-900 dark:text-white flex items-center gap-2">
          <label>{label}</label>
          <button onClick={() => setOpenModal(true)} className="w-max underline text-xs text-[#CFAB01]">
            Learn more
          </button>
        </div>
        {children}
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalHeader={modalHeaderContent}
        modalBody={modalBodyContent}
        modalFooter={modalFooterContent}
      />
    </>
  )
}

FileUploadWithModal.propTypes = {
  label: PropTypes.string.isRequired,
  modalHeaderContent: PropTypes.string.isRequired,
  modalBodyContent: PropTypes.any.isRequired,
  modalFooterContent: PropTypes.element,
  children: PropTypes.element.isRequired,
}

export default FileUploadWithModal
