import React from 'react'
import { Accordion } from 'flowbite-react'
import { useTranslation } from 'react-i18next'
import SectionTitle from '../../components/title/section-title'
import './faqs.css'

export default function FAQs() {
  const { t } = useTranslation()

  return (
    <div className="w-full container bg-white rounded-lg p-10">
      <SectionTitle title={t('faqs.heading')} description={t('faqs.subheading')} />
      <div>
        <Accordion className="faq-accordion">
          <Accordion.Panel>
            <Accordion.Title>{t('faqs.ques-1')}</Accordion.Title>
            <Accordion.Content>
              <p className="mb-2 text-gray-500">{t('faqs.ans-1')}</p>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>{t('faqs.ques-2')}</Accordion.Title>
            <Accordion.Content>
              <p className="mb-2 text-gray-500">{t('faqs.ans-2')}</p>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>{t('faqs.ques-3')}</Accordion.Title>
            <Accordion.Content>
              <p className="mb-2 text-gray-500">{t('faqs.ans-3')}</p>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>{t('faqs.ques-4')}</Accordion.Title>
            <Accordion.Content>
              <p className="mb-2 text-gray-500">{t('faqs.ans-4')}</p>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>{t('faqs.ques-5')}</Accordion.Title>
            <Accordion.Content>
              <p className="mb-2 text-gray-500">{t('faqs.ans-5')}</p>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>{t('faqs.ques-6')}</Accordion.Title>
            <Accordion.Content>
              <p className="mb-2 text-gray-500">{t('faqs.ans-6')}</p>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      </div>
    </div>
  )
}
