import React, { useState } from 'react'
import Modal from '../../../modal/learn-more/Modal'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function TextWithModal({ label, modalHeaderContent, modalBodyContent, modalFooterContent, children }) {
  const [openModal, setOpenModal] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <div className="mb-8">
        <div className="mb-2 text-sm font-normal text-gray-900 dark:text-white flex items-center gap-2">
          <label>{label}</label>
          <div>
            <button
              onClick={(event) => {
                event.preventDefault()
                setOpenModal(true)
              }}
              className="underline text-xs text-[#CFAB01]"
            >
              {t('learn')}
            </button>
          </div>
        </div>
        {children}
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalHeader={modalHeaderContent}
        modalBody={modalBodyContent}
        modalFooter={modalFooterContent}
      />
    </>
  )
}

TextWithModal.propTypes = {
  label: PropTypes.string,
  modalHeaderContent: PropTypes.string.isRequired,
  modalBodyContent: PropTypes.any.isRequired,
  modalFooterContent: PropTypes.element,
  children: PropTypes.element.isRequired,
}

export default TextWithModal
