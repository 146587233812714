import React from 'react'
import { useTranslation } from 'react-i18next'
import TextInput from '../../components/input/text/text-input/TextInput'
import TextArea from '../../components/input/textarea/textarea/TextArea'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { slackMessage } from '../../api/slackMessage'
import { useErrorContext } from '../../context/ErrorContext'
import { useSuccessContext } from '../../context/SuccessContext'
import { useLoaderContext } from '../../context/LoaderContext'

export default function GeneralEnquiries() {
  const { t } = useTranslation()

  const { setErrorMsg } = useErrorContext()
  const { setIsLoading } = useLoaderContext()
  const { setSuccessMsg } = useSuccessContext()

  const schema = Yup.object().shape({
    subject: Yup.string().required(t('error-messages.required-field')).max(150, t('error-messages.max-150')),
    message: Yup.string().required(t('error-messages.required-field')).max(1000, t('error-messages.max-1000')),
  })

  const formik = useFormik({
    initialValues: {
      subject: '',
      message: '',
    },
    validationSchema: schema,

    onSubmit: async ({ subject, message }) => {
      const payload = {
        subject: subject,
        message: message,
      }

      slackMessage(payload, setErrorMsg, setIsLoading).then((response) => {
        if (response === 'Message Received') {
          formik.resetForm()
          setSuccessMsg(t('general-enquiries.success-msg'))
        }
      })
    },
  })

  const { errors, values, handleChange, handleSubmit } = formik

  const checkIfValidationRulesPassed = ({ errors, values }) => {
    if (!values) return false
    if (!values.subject || !values.message) {
      return false
    }
    if (errors.subject || errors.message) {
      return false
    }
    return true
  }

  return (
    <form onSubmit={handleSubmit} className="w-full container bg-white rounded-lg p-10">
      <h1 className="text-gray-600 border-b">{t('general-enquiries.heading')}</h1>
      <div className="my-3">
        <div className="mb-2">
          <label htmlFor="subject" className="font-semibold">
            {t('general-enquiries.form.subject')}
          </label>
        </div>
        <TextInput
          defaultInputVisibility={true}
          hideVisibilityIcon={true}
          name="subject"
          placeholder={t('general-enquiries.form.subject')}
          value={values.subject}
          onChange={handleChange}
          error={errors.subject ? errors.subject : ''}
          maxLength={150}
        />
      </div>

      <div className="my-3">
        <div className="mb-2">
          <label htmlFor="message" className="font-semibold">
            {t('general-enquiries.form.message-label')}
          </label>
        </div>
        <TextArea
          name="message"
          placeholder={t('general-enquiries.form.message-placeholder')}
          value={values.message}
          onChange={handleChange}
          error={errors.message ? errors.message : ''}
          maxLength={1000}
        />
      </div>

      <div className="w-full mt-8 mb-3 flex justify-center">
        <PrimaryButton
          text={t('general-enquiries.form.send')}
          type="submit"
          isDisabled={!checkIfValidationRulesPassed({ errors, values })}
        />
      </div>

      <div>
        <p className="text-gray-600 text-sm">{t('general-enquiries.socials')}</p>
        <div className="flex items-center gap-6 my-2">
          <a href="mailto:customersupport@fill-easy.com">
            <img src="/icons/outlook.svg" alt="Outlook" className="w-6 h-6" />
          </a>
        </div>
      </div>
    </form>
  )
}
