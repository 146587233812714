import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import './i18n'
import '@aws-amplify/ui-react/styles.css'
import { Amplify } from 'aws-amplify'
import config from './aws-exports'
Amplify.configure(config)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
)
