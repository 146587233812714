import React from 'react'
import { useTranslation } from 'react-i18next'

const locales = {
  en: { title: 'English', flag: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg' },
  ar: { title: 'العربية', flag: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg' },
  sc: { title: '简体中文', flag: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/CN.svg' },
  tc: { title: '繁體中文', flag: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/HK.svg' },
}

function LanguageSwitcher() {
  const { i18n } = useTranslation()

  return (
    <div className="grid grid-cols-2 gap-2">
      {Object.keys(locales).map((locale) => (
        <button
          key={locale}
          onClick={() => i18n.changeLanguage(locale)}
          className={`flex items-center space-x-2 border border-gray-300 p-2 rounded ${
            i18n.language === locale ? 'bg-slate-200' : 'bg-white'
          }`}
        >
          <img src={locales[locale].flag} alt={`${locales[locale].title} flag`} className="w-6 h-4" />
          <span className="text-gray-900 text-xs">{locales[locale].title}</span>
        </button>
      ))}
    </div>
  )
}

export default LanguageSwitcher
