import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MonitoringCard from '../../components/cards/monitoring-card/MonitoringCard'
import MonitoringTable from '../../components/tables/monitoring/MonitoringTable'
import { useErrorContext } from '../../context/ErrorContext'
import { useLoaderContext } from '../../context/LoaderContext'
import { useUserContext } from '../../context/UserContext'
import { customApi } from '../../api/awsConnector'

export default function Monitoring() {
  const { t } = useTranslation()
  const { user } = useUserContext()

  const { setErrorMsg } = useErrorContext()

  const { setIsLoading } = useLoaderContext()

  const [requestData, setRequestData] = useState([])

  const [tableData, setTableData] = useState([])

  const [uniqueKeysArray, setUniqueKeysArray] = useState([])

  useEffect(() => {
    customApi(setErrorMsg, setIsLoading, user, 'portal-getrequestcount').then((response) => {
      setRequestData(response)
    })
  }, [])

  useEffect(() => {
    customApi(setErrorMsg, setIsLoading, user, 'portal-getrecentrequests').then((response) => {
      setTableData(response)
    })
  }, [])

  useEffect(() => {
    if (tableData.length > 0) {
      setUniqueKeysArray(Object.keys(tableData[0] || {}))
    }
  }, [tableData])

  return (
    <div className="text-center">
      <div className="container flex flex-wrap gap-4">
        {Object.entries(requestData).map(([key, value]) => (
          <MonitoringCard key={key} title={key} value={value.toString()} />
        ))}
      </div>
      <MonitoringTable t={t} tableData={tableData} uniqueKeysArray={uniqueKeysArray} />
    </div>
  )
}
