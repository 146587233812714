import React from 'react'
import { Modal } from 'flowbite-react'
import './modal.css'
import PropTypes from 'prop-types'

function ConfirmationModalContainer({ openModal, setOpenModal, modalHeader, modalBody, modalFooter }) {
  return (
    <Modal id="confirmation-modal" show={openModal} onClose={() => setOpenModal(false)}>
      <Modal.Header className="text-gray-900">{modalHeader}</Modal.Header>
      <Modal.Body className="text-gray-800 font-normal">{modalBody}</Modal.Body>
      {modalFooter && <Modal.Footer>{modalFooter}</Modal.Footer>}
    </Modal>
  )
}

ConfirmationModalContainer.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  modalHeader: PropTypes.string.isRequired,
  modalBody: PropTypes.string.isRequired,
  modalFooter: PropTypes.element.isRequired,
}

export default ConfirmationModalContainer
