import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Authentication from './pages/authentication'
import FAQs from './pages/faqs'
import Deployment from './pages/deployment'
import Secrets from './pages/secrets'
import AccessKeys from './pages/access-keys'
import IpWhitelisting from './pages/ip-whitelisting'
import Monitoring from './pages/monitoring'
import GeneralEnquiries from './pages/general-enquiries'
import Billing from './pages/billing'
import RequestForDataAccess from './pages/request-for-data-access'
import { useUserContext } from './context/UserContext'
import Layout from './Layout'

function RoutesContainer() {
  const { user } = useUserContext()

  if (!user) {
    return (
      <Routes>
        <Route path="*" element={<Authentication />} />
      </Routes>
    )
  }

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Deployment />} />
        <Route path="/authentication" element={<Authentication />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/deployment" element={<Deployment />} />
        <Route path="/secrets" element={<Secrets />} />
        <Route path="/access-keys" element={<AccessKeys />} />
        <Route path="/ip-whitelisting" element={<IpWhitelisting />} />
        <Route path="/monitoring" element={<Monitoring />} />
        <Route path="/general-enquiries" element={<GeneralEnquiries />} />
        <Route path="/request-for-data-access" element={<RequestForDataAccess />} />
        <Route path="/billing" element={<Billing />} />
      </Routes>
    </Layout>
  )
}

export default RoutesContainer
