import React from 'react'
import { MdErrorOutline } from 'react-icons/md'
import PropTypes from 'prop-types'

function Dropdown({ options, error, name, value, onChange, readOnly }) {
  return (
    <div>
      <select
        className={
          `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 shadow-sm` +
          `${readOnly ? ' cursor-not-allowed' : ''}`
        }
        name={name}
        id={name}
        onChange={onChange}
        value={value}
        disabled={readOnly}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <span className="font-light text-xs text-[#F05A5A] flex items-center gap-1 mt-1">
          <MdErrorOutline size={18} />
          {error}
        </span>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default Dropdown
