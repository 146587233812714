import React from 'react'
import PropTypes from 'prop-types'

function MonitoringCard({ title, value }) {
  return (
    <div className="w-[250px] p-6 bg-white rounded-xl shadow-sm">
      <p className="text-sm text-gray-600">{title}</p>
      <p className="font-medium text-[18px] text-gray-900">{value}</p>
    </div>
  )
}

MonitoringCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default MonitoringCard
