import React from 'react'
import { FiSearch } from 'react-icons/fi'
import PropTypes from 'prop-types'

function SearchBox({ placeholder, onSearch }) {
  return (
    <div className="m-2 ml-4 w-1/3 py-2">
      <label htmlFor="search" className="text-sm font-medium text-gray-900 sr-only">
        {placeholder}
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ml-3 pointer-events-none">
          <FiSearch />
        </div>
        <input
          type="search"
          id="search"
          className="w-full px-4 ps-10 text-sm text-gray-900 border-1 rounded-lg shadow-sm"
          placeholder={placeholder}
          onChange={(e) => onSearch(e)}
        />
      </div>
    </div>
  )
}

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
}

export default SearchBox
