import React from 'react'
import { useUserContext } from '../../context/UserContext'
import { ServicesData } from '../../data/services'
import { useTranslation } from 'react-i18next'
import { TooltipWithIcon } from '../tooltips/FETooltips'

function Services() {
  const { t } = useTranslation()
  const { user } = useUserContext()

  return (
    <div className="w-max bg-transparent text-center mt-7">
      <div className="flex items-center justify-center mb-2 space-x-2">
        <h3 className="text-gray-900 text-lg">{t('my-services.heading')}</h3>
        <TooltipWithIcon text={t('my-services.tooltip')} className="ml-2" />
      </div>
      <div className="flex flex-wrap justify-center space-x-4 mt-3">
        {ServicesData.map((service, index) => {
          return (
            <div
              key={index}
              className={`px-4 py-2 rounded-lg text-gray-700 text-sm font-semibold ${
                user?.attributes[service.key] ? 'bg-fe-yellow' : 'bg-slate-200'
              }`}
            >
              {service.label}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Services
