import { Auth } from 'aws-amplify'
import { apiErrorParse } from './utils'

export const fetchUser = async (setErrorMsg, setIsLoading) => {
  try {
    setIsLoading(true)
    const userData = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
    setIsLoading(false)
    return userData
  } catch (error) {
    setIsLoading(false)
    setErrorMsg(apiErrorParse(error))
  }
}
