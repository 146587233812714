import React from 'react'
import PropTypes from 'prop-types'
import { Sidebar } from 'flowbite-react'

function SidebarCollapse({ label, children }) {
  return (
    <Sidebar.Collapse
      label={label}
      className="border-b border-gray-200 shadow-none rounded-none px-0 text-sm"
      open={true}
    >
      {children}
    </Sidebar.Collapse>
  )
}

SidebarCollapse.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default SidebarCollapse
