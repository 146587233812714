import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Form from '../../Form'
import PropTypes from 'prop-types'
import { useErrorContext } from '../../../../context/ErrorContext'
import { useSuccessContext } from '../../../../context/SuccessContext'
import { useLoaderContext } from '../../../../context/LoaderContext'
import { customApi } from '../../../../api/awsConnector'
import { useUserContext } from '../../../../context/UserContext'

function Verify({ currentSingpassValues, setCurrentSingpassValues }) {
  const { t } = useTranslation()
  const { setErrorMsg } = useErrorContext()
  const { setSuccessMsg } = useSuccessContext()
  const { setIsLoading } = useLoaderContext()
  const { user } = useUserContext()

  const initialValues = {
    verifyAppID: '',
    verifySharedSecret: '',
  }

  const validationSchema = {
    verifyAppID: Yup.string().required(t('error-messages.required-field')).min(4, t('error-messages.min-3')),
    verifySharedSecret: Yup.string()
      .required(t('error-messages.required-field'))
      .length(32, t('error-messages.length-32')),
  }

  const onSubmit = async (payload) => {
    await customApi(
      setErrorMsg,
      setIsLoading,
      user,
      'portal-putsecrets',
      { eid: 'singpass', body: payload },
      setSuccessMsg,
    )

    customApi(setErrorMsg, setIsLoading, user, 'portal-getsecrets', { eid: 'singpass' }).then((res) => {
      setCurrentSingpassValues(res)
    })
  }

  const fields = [
    {
      type: 'text',
      name: 'verifyAppID',
      label: 'APP ID',
      placeholder: 'PROD-202410100Z-YOURCOM-APPLICATION',
      modalBodyContent: (
        <div className="p-5 pt-0">
          <p>{t('secrets.singpass.form.verify.app-id-modal-content')}</p>
          <img
            src="https://images.pexels.com/photos/38568/apple-imac-ipad-workplace-38568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
            className="mt-10 px-10"
          />
        </div>
      ),
    },
    {
      type: 'text',
      name: 'verifySharedSecret',
      label: 'Shared Secret',
      placeholder: 'a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p',
      modalBodyContent: (
        <div className="p-5 pt-0">
          <p>{t('secrets.singpass.form.verify.shared-secret-modal-content')}</p>
          <img
            src="https://images.pexels.com/photos/38568/apple-imac-ipad-workplace-38568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
            className="mt-10 px-10"
          />
        </div>
      ),
    },
  ]

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      currentValues={currentSingpassValues}
      fields={fields}
      service="singpass"
    />
  )
}

Verify.propTypes = {
  currentSingpassValues: PropTypes.object.isRequired,
  setCurrentSingpassValues: PropTypes.func.isRequired,
}

export default Verify
