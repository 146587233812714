import { API } from 'aws-amplify'
import { apiErrorParse } from './utils'

// Doc - https://github.com/Fill-Easy-Limited/Portal-Redesign/issues/13

/**
 * POST /validate-org-handle
 * description: Validates the organization shorthand
 * @param {object} payload - eg {'organization':'test sting'}
 * @param {function} setGlobalErrorMessage - this is setErrorMsg from useErrorContext
 * @returns string - an error message string.
 * Case 1 - Empty means no ones use this organization name.
 * Case 2 - Non-empty means this organization name has been used by someone.
 * Case 3 - Undefined means some un-handle case in the code
 */
export async function validateOrganizationShorthand(payload, setGlobalErrorMessage) {
  try {
    const response = await API.post('api', '/validate-org-handle', {
      body: payload,
    })

    if (typeof response === 'string') {
      if (response === 'valid') {
        return ''
      } else {
        return response
      }
    } else {
      return
    }
  } catch (e) {
    // Send error to Global Error Component to handle it
    setGlobalErrorMessage(e.response.data)
  }
}

/**
 * POST /deploy-infra
 * description: Deploys the infrastructure
 * @param {function} setGlobalErrorMessage - this is setErrorMsg from useErrorContext
 * @param {*} payload - Eg {
        region: "",
        organization: "",
        organizationFull: ""
      }
 * @returns string - executionId
 */
export async function deploy(setGlobalErrorMessage, payload) {
  try {
    const response = await API.post('api', '/deploy-infra', { body: payload })
    return response
  } catch (error) {
    setGlobalErrorMessage(apiErrorParse(error))
  }
}

/**
 * GET /deployment-status
 * description: Gets the status of the deployment
 * @param {function} setGlobalErrorMessage - this is setErrorMsg from useErrorContext
 * @returns object - {status, isDeploying, createAccount, deployStack, updateDns}
 */
export async function getDeploymentStatus(setGlobalErrorMessage) {
  try {
    const response = await API.get('api', '/deployment-status')
    return response
  } catch (error) {
    setGlobalErrorMessage(apiErrorParse(error))
  }
}
