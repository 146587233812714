import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const useForm = (initialValues, validationSchema, onSubmit, currentValues = {}) => {
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit,
  })

  const { errors, values, setFieldValue, handleChange, handleSubmit } = formik

  useEffect(() => {
    if (currentValues) {
      Object.keys(currentValues).forEach((key) => {
        if (currentValues[key]) {
          setFieldValue(key, currentValues[key], false)
        }
      })
    }
  }, [currentValues, setFieldValue])

  const checkIfValidationRulesPassed = ({ errors, values }) => {
    if (!values) return false
    return Object.keys(values).every((key) => values[key] && !errors[key])
  }

  const checkIfValuesChanged = ({ values, currentValues = {} }) => {
    if (!values) return false
    return Object.keys(values).some((key) => values[key] !== currentValues[key])
  }

  return {
    errors,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    checkIfValidationRulesPassed,
    checkIfValuesChanged,
  }
}

export default useForm
