import React from 'react'
import PropsTypes from 'prop-types'

function SectionTitle({ title, description }) {
  return (
    <>
      <h1 className="font-bold text-gray-900 text-xl mb-2">{title}</h1>
      <p className="text-sm text-gray-700 mb-10">{description}</p>
    </>
  )
}

SectionTitle.propTypes = {
  title: PropsTypes.string.isRequired,
  description: PropsTypes.string.isRequired,
}

export default SectionTitle
